import React from 'react'



export default function Landing(){
/*
    useEffect(()=>{
        
        retrieveData();
    },[]);

    const dataval = {"url":"5155a9"};
    const options = {
        headers:{
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        }
    } ;
    const retrieveData = ()=>{
       
        axios.post('http://192.168.0.22/reviewsonthefly/client/webapi2.php',{url:"5155a9"},{options})
        .then(res=>{
            console.log(res);
            console.log(res.data);
        })
        .catch(e=>{
            console.log(e);
        });
    }
    */
    return(
        <div>
            <h1>Landing Page</h1>
        </div>
    )
}